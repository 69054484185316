<template>
    <div class="center-member-tag">
        <!-- 首席已上传 -->
        <div class="center-member-tag-list" v-if="list.length > 0">
            <!-- tips -->
            <div class="center-member-tag-list-tips">
                <!-- more -->
                <slot name="tipsMore">
                </slot>
                <!-- 统计 -->
                <div class="center-member-tag-list-tips-count">
                    已上传：
                    <span>{{list.length}}</span>
                    项服务&nbsp;&nbsp;&nbsp;&nbsp;
                    可上传：
                    <span>4</span>
                    项服务
                </div>
            </div>
            <!-- list -->
            <center-list
                :list-data="list"
                :edit-router="editRouter">
                <template #listItem="{ item }">
                    <center-list-item
                        :edit-router="editRouter"
                        :item-pic="item.skin"
                        :item-id="item.id"
                        @handleDelete="handleDel(item.id)">
                        <template #info>
                            <div class="center-member-tag-list-info">
                                <!-- 标题 -->
                                <div class="center-member-tag-list-info-title">
                                    {{item.name}}
                                </div>
                                <!-- 副标题 -->
                                <div class="center-member-tag-list-info-subtitle">
                                    {{item.area}}
                                </div>
                                <!-- 价格 -->
                                <div class="center-member-tag-list-info-price">
                                    {{item.price}}元 / {{item.unit}}
                                </div>
                            </div>
                        </template>
                    </center-list-item>
                </template>
            </center-list>
        </div>
        <!-- 首席未上传 -->
        <center-member-tag-nothing v-else-if="list.length == 0">
            <template #title>
                <slot name="notingTitle"></slot>
            </template>
            <template #tips>
                <slot name="notingTips"></slot>
            </template>
        </center-member-tag-nothing>
        <!-- 非首席 -->
        <center-member-tag-nothing v-else>
            <template #title>
                <slot name="unbecomeTitle"></slot>
            </template>
            <template #tips>
                <slot name="unbecomeTips"></slot>
            </template>
        </center-member-tag-nothing>
    </div>
</template>

<script>
    export default {
        props: {
            list: Array,
            editRouter: String
        },
        components: {
            centerMemberTagNothing: () => import("@/components/centerMemberTagNothing"),
            centerList: () => import("@/components/centerList"),
            centerListItem: () => import("@/components/centerListItem")
        },
        methods: {
            handleDel(id){
                this.$emit('handleDelete', id)
            }
        },
    }
</script>

<style scoped>
/* tips */
.center-member-tag-list-tips{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
/* 统计 */
.center-member-tag-list-tips-count{
    color: #909399;
}
.center-member-tag-list-tips-count > span{
    color: #8d9afc;
}

/* list */
.center-member-tag-list-info-title{
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
}
.center-member-tag-list-info-subtitle{
    margin-bottom: 10px;
    color: #909399;
}
.center-member-tag-list-info-price{
    color: #E6A23C;
}
</style>